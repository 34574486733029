const providers = [
	{
		name: 'youtube',
		regex: [
			/youtube\.com\/.*\?v=(?<code>[^&]+)/ui,
			/youtu\.be\/(?<code>[^?]+)/ui
		],
		getEmbedded: code => `
			<iframe
			width="560"
			height="315"
			src="https://www.youtube-nocookie.com/embed/${code}"
			frameborder="0"
			allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
			allowfullscreen></iframe>
		`
	},
	{
		name: 'wistia',
		regex: [/(wistia\.(com|net)|wi\.st)\/(medias|embed)(\/iframe)?\/(?<code>[^/]+)/ui],
		getEmbedded: code => `
			<iframe
			width="560"
			height="315"
			src="https://fast.wistia.net/embed/iframe/${code}"
			frameborder="0"
			allowfullscreen></iframe>
		`
	},
	{
		name: 'vimeo',
		regex: [/vimeo\.com\/(?<code>\d+)/ui],
		getEmbedded: code => `
			<iframe
			width="560"
			height="315"
			src="https://fast.wistia.net/embed/iframe/${code}"
			frameborder="0"
			allowfullscreen></iframe>
		`
	}
];

// eslint-disable-next-line require-jsdoc
function getEmbedded(url) {
	for(const provider of providers) {
		for(const regex of provider.regex) {
			const matches = url.match(regex);

			if(matches && typeof matches === 'object') {
				return provider.getEmbedded(matches.groups.code);
			}
		}
	}

	return false;
}

export {providers, getEmbedded};