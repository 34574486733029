<template>
	<div :class="classes">
		<div class="video-block">
			<div v-if="embedded" class="embedded" v-html="embedded"></div>

			<MediaTranscript v-if="block.meta.transcript" :text="block.meta.transcript" />
		</div>
	</div>
</template>

<script>
	import {getEmbedded} from '../../inc/videoEmbeds';
	import MediaTranscript from '../MediaTranscript';

	export default {
		name: 'BlockVideo',
		components: {
			MediaTranscript
		},
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		computed: {
			embedded() {
				return getEmbedded(this.block.content);
			},
			classes() {
				return `block-inner align-${this.block.meta.align}`;
			}
		},
		created() {
			if(typeof this.block.content !== 'string') {
				this.block.content = '';
			}

			if(typeof this.block.meta !== 'object') {
				this.$set(this.block, 'meta', {});
			}

			if(!Object.keys(this.block.meta).length) {
				this.$set(this.block.meta, 'align', 'left');
			}
		}
	};
</script>

<style lang="scss" scoped>
	.video.block {
		.block-inner {
			display: flex;
			flex-flow: column nowrap;
			align-items: flex-start;
		}

		.align-center {
			align-items: center;
		}

		.align-right {
			align-items: flex-end;
		}
	}
</style>